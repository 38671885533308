var scrollToTop4i = function(){
	const scrollBtn = document.getElementsByClassName('back-to-top');
	
	const init = () => {
		if(scrollBtn.length > 0) {
			window.addEventListener("scroll", initiateScroll);
			scrollBtn[0].addEventListener("click", goToTop);
		}
	};
	
	const scrollTimeOut = () =>{
		setTimeout(() => scrollBtn[0].classList.add('hide'), 200);
	};
	
	const goToTop = (e) =>{
		e.preventDefault();
		window.scrollTo({ top: 0, behavior: "smooth" });
            	
            	// set focus to the top of the page (4i logo?) so keyboard user pressing tab won't still be stuck at the bottom of the page
            	if(document.querySelector(".HeaderLogo")){
            		setTimeout(() => document.querySelector(".HeaderLogo").getElementsByTagName("a")[0].focus(), 500);
            	}else if(document.querySelector(".logo2017")){
            		setTimeout(() => document.querySelector(".logo2017").getElementsByTagName("a")[0].focus(), 500);
            	}
            	
		return false;
	};
	
	const initiateScroll = () =>{
		if (window.pageYOffset > 300) {
			scrollBtn[0].classList.remove('hide');
			scrollBtn[0].style.opacity="1";
			clearTimeout(scrollTimeOut);
		} else {
			scrollBtn[0].style.opacity="0";
			scrollTimeOut();
		}
	};
	
	return{
		Init: init
	};
};

(async () => {
	const scrollToTopBtnEvents = new scrollToTop4i();
	scrollToTopBtnEvents.Init();
})();